<!-- 登录 -->
<template>
  <div class="flex-1 flex-column">
    <div class="header">
      <div class="header-c flex justify-s-b align-c">
        <img class="logo" src="../../assets/images/logo.png" />
        <div class="flex align-c">
          <div class="mr-20 font-12">
            <p class="color-theme">登录小程序版</p>
            <p class="color-666 mt-5">畅享更多服务</p>
          </div>
          <el-popover placement="bottom" trigger="hover">
              <img src="../../assets/images/qr_code.jpg" style="width: 160px; height:auto;"> 
              <img src="../../assets/images/qr_code.jpg" slot="reference" style="width: 66px; height:auto;" />
          </el-popover>
        </div>
      </div>
    </div>
    <div class="main flex flex-1 align-c">
      <div class="main-c flex justify-s-b align-c">
        <img src="../../assets/images/login_main.png" />
        <div class="login-box">
          <p class="title">微信登录</p>
          <wxlogin
            class="login flex justify-c"
            :appid="appid"
            :scope="scope"
            :redirect_uri="redirect_uri"
            :href="href"
          />
          <p class="meta">请使用微信扫码登录</p>
          <p class="tips flex justify-c align-c">
            没有账号？请在汉坤实业小程序上注册
          </p>
        </div>
      </div>
    </div>
    <div class="program flex justify-s-a align-c">
      <div class="program-item flex align-c">
        <div class="image"></div>
        <div>
          <p class="font-16 font-bold">汉坤实业小程序</p>
          <p class="color-999 font-12 mt-10">微信扫描小程序码</p>
        </div>
      </div>
      <div class="arrow"></div>
      <div class="program-item program-item2 flex align-c">
        <div class="image"></div>
        <div>
          <p class="font-16 font-bold">注册会员</p>
          <p class="color-999 font-12 mt-10">微信授权登录注册账号</p>
        </div>
      </div>
      <div class="arrow"></div>
      <div class="program-item program-item4 flex align-c">
        <div class="image"></div>
        <div>
          <p class="font-16 font-bold">下单购买</p>
          <p class="color-999 font-12 mt-10">在小程序或PC端下单购买</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wxlogin from "vue-wxlogin";

export default {
  name: "Login",
  components: { wxlogin },

  data() {
    return {
      appid: "wx53c53b37db7802d4",
      scope: "snsapi_login",
      redirect_uri: this.getUrl(),
      href:
        "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwNXB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQ==",
    };
  },

  watch: {
    $route() {
      if (this.$route.query.code) {
        this.loginFn(this.$route.query.code)
      }
    },
  },

  methods: {
    getUrl(){
      let url = window.location.href
      if(url.indexOf("?code") != -1){
        url = url.split("?code")[0]
      } else if(url.indexOf("&code") != -1) {
        url = url.split("&code")[0]
      }
      console.log(url)
      return encodeURIComponent(url)
    },

    loginFn(code) {
      this.$https.get(this.$api.login, { 
        code: code, 
        centreId: this.$api.centreId 
      }).then((res) => {
        this.$store.commit("LOGIN_IN", {token: res.authToken, userInfo: res.data, shopInfo: res.shop})
        this.$router.replace({name: 'home'})
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.header {
  .header-c {
    width: 1200px;
    height: 88px;
    margin: 0 auto;
    .logo {
      width: auto;
      height: 66px;
    }
  }
}
.main {
  min-height: 520px;
  background: linear-gradient(-180deg, #0c3890, #7e84f9);
  .main-c {
    width: 1200px;
    margin: 0 auto;
    padding-left: 110px;
    .login-box {
      width: 350px;
      height: 420px;
      padding-top: 44px;
      text-align: center;
      background-color: #fff;
      .title {
        font-size: 22px;
        font-weight: bold;
        color: var(--theme-color);
        margin-bottom: 11px;
      }
      .login {
        height: 235px;
      }
      .meta {
        font-size: 12px;
      }
      .tips {
        width: 250px;
        height: 20px;
        margin: 40px auto 0;
        color: #fff;
        font-size: 12px;
        background: linear-gradient(-90deg, #0c3890, #7d83f9);
        border-radius: 10px;
      }
    }
  }
}
.program {
  width: 1200px;
  padding: 35px 0;
  margin: 0 auto;
  .program-item {
    .image {
      width: 54px;
      height: 54px;
      margin-right: 15px;
      background: url(../../assets/images/sprite.png) no-repeat;
    }
    &.program-item2 {
      .image {
        background-position: -64px 0;
      }
    }
    &.program-item3 {
      .image {
        background-position: -128px 0;
      }
    }
    &.program-item4 {
      .image {
        background-position: -192px 0;
      }
    }
  }
  .arrow{
    width: 48px;
    height: 10px;
    background: url(../../assets/images/sprite.png) no-repeat;
    background-position: 0 -64px;
  }
}
</style>